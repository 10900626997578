@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .swiper .swiper-button-prev {
    @apply tw-w-[30px] tw-h-[30px] tw-bg-black tw-rounded-full tw-flex tw-items-center 
    tw-bg-opacity-40 tw-justify-center;
  }
  .swiper .swiper-button-prev:after {
    @apply tw-text-white tw-text-base tw-font-bold;
  }
  .modal .swiper .swiper-button-prev {
    @apply tw-bg-white tw-bg-opacity-60;
  }
  .modal .swiper .swiper-button-prev:after {
    @apply tw-text-black;
  }
  .swiper .swiper-button-next {
    @apply tw-w-[30px] tw-h-[30px] tw-bg-black tw-rounded-full tw-flex tw-items-center tw-bg-opacity-40 tw-justify-center;
  }
  .swiper .swiper-button-next:after {
    @apply tw-text-white tw-text-base tw-font-bold;
  }
  .modal .swiper .swiper-button-next:after {
    @apply tw-text-black;
  }
  .modal .swiper .swiper-button-next {
    @apply tw-bg-white tw-bg-opacity-60;
  }

  @screen lg {
    .image-slide .swiper .swiper-button-prev {
      @apply tw-left-1/5;
    }
    .modal .swiper .swiper-button-prev {
      @apply tw-left-1/5;
    }
    .image-slide .swiper .swiper-button-next {
      @apply tw-right-1/5;
    }
    .modal .swiper .swiper-button-next {
      @apply tw-right-1/5;
    }
  }

  @screen xl {
    .image-slide .swiper .swiper-button-prev {
      @apply tw-left-1/4;
    }
    .modal .swiper .swiper-button-prev {
      @apply tw-left-1/4;
    }
     .image-slide .swiper .swiper-button-next {
      @apply tw-right-1/4;
    }
    .modal .swiper .swiper-button-next {
      @apply tw-right-1/4;
    }
  }
}
